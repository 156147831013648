
let files = [
    {

        id: '1',
        name: 'Introduction to chrome ',
        type: 'PDF',
        modified: '02-07-1999',
        created: '01-07-1999',
        prodect: 'projectId',
        projectId: 0

    },
    {
        id: '2',
        name: 'Installation',
        type: 'PDF',
        modified: '02-07-1999',
        created: '01-07-1999',
        prodect: 'projectId',
        projectId: 12

    },
    {
        id: '21',
        name: 'Introduction Introduction Introduction Introduction Introduction ',
        type: 'PDF',
        modified: '02-07-1999',
        created: '01-07-1999',
        prodect: 'projectId',
        projectId: 12

    },
    {
        id: '22',
        name: 'Installation Of AC',
        type: 'PNG',
        modified: '02-07-1999',
        created: '01-07-1999',
        projectId: 1

    },
    {
        id: '11',
        name: 'Introduction Introduction Introduction Introduction Introduction ',
        type: 'Document',
        modified: '02-07-1999',
        created: '01-07-1999',
        projectId: 123

    },
    {
        id: '212',
        name: 'Installation',
        type: 'PNG',
        modified: '02-07-1999',
        created: '01-07-1999',
        projectId: 123

    },
]

let PROJECT = [
    { id: 0, name: 'Starter Project' }, //this is permanent
    
]
files = []
// PROJECT = []
export default files;

export { PROJECT }
